import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import axios, { AxiosError, isAxiosError } from 'axios'
import { Session } from '@/types/chat'
import { useAxios } from '@/utilities/useAxios'

interface SessionExistResponse {
  session_found: boolean
}

export const useSessionStore = defineStore('session', {
  state: () => ({
    session_id: '' as string,
    sessionsList: [] as Session[]
  }),
  getters: {
    sessionCount: (state) => state.sessionsList.length,
    currentSession: (state) =>
      state.sessionsList.find((session) => (session?.session_id || '') == state.session_id)
  },
  actions: {
    goToSession(value: string) {
      this.session_id = value
    },
    async getSessionList() {
      /**
       * getSessionList is a action to fetch list of sessions from backend api
       */

      const { instance: axiosInstance } = useAxios()
      const response = await axiosInstance.get('chat/get_session_list')

      if (response.status == 200) {
        this.sessionsList = response.data.sessions
      }
    },

    async createSession() {
      const { instance: axiosInstance } = useAxios()

      try {
        // make mock loading

        const response = await axiosInstance.post('chat/create_session')

        this.session_id = response.data.session_id
      } catch (error) {}
    },

    async createNameSession(sessionId: string | number) {
      const { instance: axiosInstance } = useAxios()

      try {
        await axiosInstance.post('chat/update_session_name', {
          session_id: sessionId
        })
      } catch (error) {}
    },

    async deleteSession(session_id: number): Promise<string | true> {
      /**
       * deleteSession is a action to call delete session api from backend
       * and remove target session from sessionList
       */
      const { instance: axiosInstance } = useAxios()

      try {
        // call api
        const response = await axiosInstance.post('chat/delete_session', {
          session_id: session_id
        })

        const index = this.sessionsList.findIndex((session) => session.session_id === session_id)
        if (index !== -1) {
          this.sessionsList.splice(index, 1)
        }
        return true
      } catch (error) {
        let error_message = 'Sorry, we cannot delete this session at this moment.'
        if (isAxiosError(error) && error.response !== undefined) {
          error_message = error.response.data.message as string
        }

        return error_message
      }
    },
    async checkChatExists(session_id: string): Promise<string | boolean> {
      const { instance: axiosInstance } = useAxios()
      try {
        const response = await axiosInstance.post('chat/is_session_deleted', {
          session_id: session_id
        })

        return (response.data as SessionExistResponse).session_found
      } catch (error) {
        console.error('Error fetching user role:', error)
        return false
      }
    },
    async getSessionFiles(session_id: string): Promise<DataManagementFile[]> {
      const { instance: axiosInstance } = useAxios()
      try {
        const response = await axiosInstance.get(`data_management/session/${session_id}/list`)

        return response.data
      } catch (error) {
        throw error
      }
    },
    async deleteSessionFile(session_id: string, file_id: number): Promise<DataManagementFile[]> {
      const { instance: axiosInstance } = useAxios()
      try {
        const response = await axiosInstance.post(
          `data_management/session/${session_id}/file/${file_id}/delete`
        )

        return response.data
      } catch (error) {
        console.error('Error delete session files:', error)
        return []
      }
    }
  }
})
